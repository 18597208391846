import Constants from '../../config.local.js'

var OpenBankMixin = {
    methods: {
		addBridgeConnection: async function() {
            const url = Constants.BRIDGE_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("OpenBankMixin::addBridgeConnection", url, {}, false)
			.catch(e => {
				console.error("OpenBankMixin::addBridgeConnection", e)
			})
		
			if(result) return result.retour
			return null
		},

		getBridgeConnection: async function() {
            const url = Constants.BRIDGE_ITEMS_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("OpenBankMixin::getBridgeConnection", url)
			.catch(e => {
				console.error("OpenBankMixin::getBridgeConnection", e)
			})
		
			if(result) return result.retour
			return null
		},

		refreshBridgeConnection: async function(item_id) {
            const url = this.constructRoute(Constants.BRIDGE_ITEMS_REFRESH_URL, {item_id})+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("OpenBankMixin::refreshBridgeConnection", url)
			.catch(e => {
				console.error("OpenBankMixin::refreshBridgeConnection", e)
			})
		
			if(result) return result.retour
			return null
		},

		deleteBridgeConnection: async function(item_id) {
            const url = this.constructRoute(Constants.BRIDGE_ITEMS_ID_URL, {item_id})+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_delete_api("OpenBankMixin::deleteBridgeConnection", url)
			.catch(e => {
				console.error("OpenBankMixin::deleteBridgeConnection", e)
			})
		
			if(result) return result.retour
			return null
		},

		getBridgeAccounts: async function() {
            const url = Constants.BRIDGE_ACCOUNTS_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("OpenBankMixin::getBridgeAccounts", url)
			.catch(e => {
				console.error("OpenBankMixin::getBridgeAccounts", e)
			})
		
			if(result) return result.retour
			return null
		},

		getBridgeAccountsAccountingPlan: async function() {
            const url = Constants.BRIDGE_ACCOUNTS_ACC_PLAN_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("OpenBankMixin::getBridgeAccountsAccountingPlan", url)
			.catch(e => {
				console.error("OpenBankMixin::getBridgeAccountsAccountingPlan", e)
			})
		
			if(result) return result.retour
			return null
		},

		updateBridgeAccounts: async function() {
            const url = Constants.BRIDGE_ACCOUNTS_UPDATE_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("OpenBankMixin::updateBridgeAccounts", url)
			.catch(e => {
				console.error("OpenBankMixin::updateBridgeAccounts", e)
			})
		
			if(result) return result.retour
			return null
		},

		getBridgeTransaction: async function(start, end, accounts, ask_bridge) {
            const url = Constants.BRIDGE_TRANSACTIONS_URL+"?licence_key="+Constants.USER_LICENCE_KEY+"&start="+start+"&end="+end+"&accounts="+accounts.join(',')+"&ask_bridge="+Number(ask_bridge)

			const result = await this.$request.request_get_api("OpenBankMixin::getBridgeTransaction", url)
			.catch(e => {
				console.error("OpenBankMixin::getBridgeTransaction", e)
			})
		
			if(result) return result.retour
			return null
		},

		getPotentialsInvoicesByBridgeTransaction: async function(transaction_id) {
            const url = this.constructRoute(Constants.BRIDGE_TRANSACTION_ID_SUGGESTION_URL, {transaction_id})+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_get_api("OpenBankMixin::getPotentialsInvoicesByBridgeTransaction", url)
			.catch(e => {
				console.error("OpenBankMixin::getPotentialsInvoicesByBridgeTransaction", e)
			})
		
			if(result) return result.retour
			return null
		},

		searchInvoiceBridgeTransaction: async function(transaction_id, supplier, search_value) {
            const url = this.constructRoute(Constants.BRIDGE_TRANSACTION_ID_SEARCH_URL, {transaction_id})+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("OpenBankMixin::searchInvoiceBridgeTransaction", url, {supplier, search_value}, false)
			.catch(e => {
				console.error("OpenBankMixin::searchInvoiceBridgeTransaction", e)
			})
		
			if(result) return result.retour
			return null
		},

		assignBridgeTransaction: async function(transaction_id, supplier, invoices_ids, checkdeposit_id, payment_id, current_account_id, memo) {
            const url = this.constructRoute(Constants.BRIDGE_TRANSACTION_ID_ASSIGNATION_URL, {transaction_id})+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("OpenBankMixin::assignBridgeTransaction", url, {supplier, invoices_ids, checkdeposit_id, payment_id, current_account_id, memo}, false)
			.catch(e => {
				console.error("OpenBankMixin::assignBridgeTransaction", e)
			})
		
			if(result) return result.retour
			return null
		},

		getBridgeTransactionSuggestion: async function(accountingplan_id, amount, date) {
            const url = Constants.BRIDGE_TRANSACTION_SUGGESTION_URL+"?licence_key="+Constants.USER_LICENCE_KEY

			const result = await this.$request.request_post_api("OpenBankMixin::getBridgeTransactionSuggestion", url, {accountingplan_id, amount, date,}, false)
			.catch(e => {
				console.error("OpenBankMixin::getBridgeTransactionSuggestion", e)
			})
		
			if(result) return result.retour
			return null
		}
	}
}

export default OpenBankMixin